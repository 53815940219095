<template>
  <div class="main-content">
    <div class="headImg">
      <img src="../../assets/schools/schools-top.png" alt="">
      <navigationBar class="avigation"></navigationBar>
    </div>
    <div class="content-view">
      <div class="content">
        <!-- 菜单 -->
        <div class="content-top">
          <topItem v-for="(item,index) in itemList" :key="index" :index="cur" :cur="index" :item="item" @cilckMethod="cilckMethod"></topItem>
        </div>
        <div class="content-content">
          <!-- 服务内容 -->
          <div class="content-one" v-if="cur==0">
            <div class="one-top" v-if="itemData != null" v-html="itemData.noticeContent"></div>
          </div>
          <!-- 成果展示 -->
          <div class="content-one" v-if="cur==1">
            <div class="one-top" v-if="itemData != null" v-html="itemData.noticeContent"></div>
          </div>
          <!-- 合作院校 -->
          <div class="content-three" v-if="cur==2">
            <div class="three-top">
              <div class="school-one" :style="number==1?{backgroundColor:isSkin,color:'#FFF'}:''" @click="cilckMethodOne">本科院校</div>
              <div class="school-two" :style="number==2?{backgroundColor:isSkin,color:'#FFF'}:''" @click="cilckMethodTwo">职业教育</div>
            </div>
            <div class="three-but">
              <schools v-for="(item,index) in item" :key="index" :index="index" :item="item"></schools>
              <div class="page" v-show="pageShow">
                <div class="page1">
                    <el-pagination background :current-page="currentPage"
                      :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from '../../components/layout/app_navigationBar'
import topItem from './components/item-box'
import schools from './components/schoolList-box'
export default {
  components:{navigationBar,topItem,schools},
  data() {
    return {
      pageShow:false,
      totals:1,
      currentPage:1,
      pageSize:10,
      cur:0,
      number:1,
      itemList:[{title:'服务内容'},{title:'成果展示'},{title:'合作院校'}],
      item:[],
	    itemData:{}
    }
  },
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
	},
	created(){
		this.$store.commit('changClick',0)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getContent(4)
	},
	methods:{
		/**获取服务内容/成果展示**/
		getContent(index){
			var that = this
			that.$api.getNotice({
				params:{
					noticeType:index,
					pageNum:that.currentPage,
					pageSize:that.pageSize,
				},
			}).then(res=>{
				if (res.data.code == 0) {
					that.itemData = res.data.data.list[0]
				}else{
					that.itemData = {}
				}
			})
		},	
		/**获取合作院校**/ 
		getPartners(index){
			var that = this
			that.$api.getPartners({
				params:{
					category:index,
					pageNum:that.currentPage,
					pageSize:that.pageSize
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.item = res.data.data.list
					that.totals = res.data.data.pages
          if (res.data.data.list.length == 0){
            that.pageShow = false
          }else {
            that.pageShow = true
          }
				}else{
					that.item = []
          that.pageShow = false
				}
			})
		},
		/**菜单点击按钮**/
		cilckMethod(index){
		  this.cur = index.cur;
		  if (index.cur==2) {
			this.handleCurrentChange(1)
		  }else if (index.cur == 0) {
		  	this.getContent(4)
		  }else if (index.cur == 1) {
		  	this.getContent(5)
		  }
		},
		/**本科院校按钮**/ 
		cilckMethodOne(){
		  this.number = 1
		  this.getPartners(1)
		},
		/**职业教育按钮**/
		cilckMethodTwo(){
		  this.number = 2
		  this.getPartners(2)
		},
		/**当前页按钮**/ 
		handleCurrentChange(index) {
			console.log(index);
			this.currentPage = index;
			this.getPartners(this.number)
			this.$nextTick(function () {
				/**更换分页按钮皮肤**/
				var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
				if(array && array.length>0){
					array.forEach((item,index1) => {
						if (index == array[index1].innerText) {
							array[index1].style.background  = this.isSkin;
						}else{
							array[index1].style.background  = "#f4f4f5";
						}
					})
				}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .headImg{
    width: 100%;
    height: 300px;
    margin-top: -80px;
    position: relative;
    .avigation{
      position: absolute;
      top: 0px;
    }
  }
  .content-view{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content{
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-top{
        width: 100%;
        height: 85px;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-top: 100px;
      }
      .content-content{
        width: 100%;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        margin-bottom: 150px;
        .content-one{
          padding: 70px 100px 70px 100px;
          .one-top{
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #333333;
            margin-bottom: 20px;
          }
          .one-two{
            width: 996px;
            height: 258px;
          }
          .one-but{
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #333333;
            margin-top: 20px;
          }
        }
        .content-three{
          padding: 70px 100px 70px 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .three-top{
            height: 50px;
            width: 388px;
            display: flex;
            justify-items: center;
            align-items: center;
            .school-one{
              width: 144px;
              height: 46px;
              line-height: 46px;
              border: 1px solid #DDD;
              border-radius: 4px;
              text-align: center;
              font-size: 16px;
              color: #333;
            }
            .school-two{
              margin-left: 100px;
              width: 144px;
              height: 46px;
              line-height: 46px;
              border: 1px solid #DDD;
              border-radius: 4px;
              text-align: center;
              font-size: 16px;
              color: #333;
            }
          }
          .three-but{
			width: 100%;
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 30px;
            .page{
              width: 896px;
              padding: 50px 20px 20px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .page1{
                display: flex;
                justify-items: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>