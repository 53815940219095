<template>
  <div>
      <div class="content" :class="{active:numberType==0,active1:(index+1)%4==0}">
          <div class="content-img">
              <img :src="item.img" alt="">
          </div>
          <div class="content-title">{{item.name}}</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        numberType:{
            type:Number,
            default:0
        },
        index:{
            type:Number,
            default:0
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 213px;
    height: 190px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-img{
        margin-top: 30px;
		> img{
			width: 100%;
			height: 100%;
		}
    }
    .content-title{
        margin-top: 30px;
    }
}
.active{
    margin-right: 48px;
}
.active1{
    margin-right: 0px;
}
</style>